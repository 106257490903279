<template>
    <div>
        <v-container v-if="activo">
            <img width="100px" src="@/assets/iconos/pedido-confirmado-2.png">
            <p class="rojo">Compra confirmada</p>
        </v-container>
        <v-container v-else>
            <img width="100px" src="@/assets/iconos/pedido-confirmado-1.png">
            <p class="negro">Compra confirmada</p>
        </v-container>
    </div>  
</template>

<script>
export default {
    name:'pedidoConfirmado',
    props:['activo']
}
</script>

