import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'

Vue.config.productionTip = false

import {Icon} from 'leaflet'
import "leaflet/dist/leaflet.css"


delete Icon.Default.prototype._getIconUrl

Icon.Default.imagePath='.'




new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
