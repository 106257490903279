<template>
    <div>
        <v-container v-if="activo">
            <img width="100px" src="@/assets/iconos/en-preparacion-2.png">
            <p class="rojo">En preparación</p>
        </v-container>
        <v-container v-else>
            <img width="100px" src="@/assets/iconos/en-preparacion-1.png">
            <p class="negro">En preparación</p>
        </v-container>
    </div>  
</template>
    
    

<script>
export default {
    name:'enPreparacion',
    props:['activo']
}
</script>


