<template>
  <div data-app>
    <Formulario @ingresado="valorID"></Formulario>
     
    <v-dialog
      v-model="mostrarCargador"
      hide-overlay
      persistent
      width="300"
      >
      <v-card 
        color="#4E0108"
        dark
        class="d-flex align-center justify-center"
        min-height="40"
        >
          <v-card-text>
              <v-layout align-center justify-center>
                  <v-row
                  align="center">
                  <v-col>
                      <v-img
                        src="@/assets/cargando.gif"
                        height="50"
                        width="50"
                      ></v-img>
                    </v-col>
                    <v-col>
                        <v-container fluid fill-height>
                            <v-layout align-center justify-center>
                              <v-flex>
                                  <p>Por favor, espere...</p>
                              </v-flex>
                            </v-layout>
                        </v-container>
                          
                      </v-col>
                </v-row>
              </v-layout>
          </v-card-text>
      </v-card>
     
    </v-dialog>

    <v-dialog 
      v-model="mostrarError"
      width="500">
        <v-card
            color="#E9E9E9"
          >
            <v-card-title class="pt-5" color="#000000">
                No disponemos de información para ese pedido. Favor vuelve a ingresar la información.
            </v-card-title>
            <v-card-actions>
                <v-btn
                  dark
                  color="#4E0108" 
                  v-on:click="mostrarErrorClick">Cerrar ventana</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <InformacionPedido v-show="mostrarCapasResultado" :key="componenteInformacion" :encontrado="encontrado"></InformacionPedido>
  </div>
</template>

<script>
  import Formulario from '@/components/Formulario.vue'
  import InformacionPedido from '@/components/InformacionPedido.vue' 
  import axios from 'axios'
  

  export default { 
    name: 'Home',
    components: {
    Formulario,
    InformacionPedido
},

    data() {
      return {
      mostrarCargador:false,
      mostrarCapasResultado: false,
      mostrarError:false,
      ID:'',
      encontrado:'',
      encontradoShuck:{
        ID: 0,
        nombre:'',
        direccion:'',
        status:'',
        camion:'',
        fecha_programada: ''
      },
      componenteInformacion:0
      }
    }, 

    methods: {
      valorID(ingresado) {   
        this.mostrarCapasResultado=false;
        this.mostrarCargador=true
        this.ID=ingresado
        //this.buscarCourier()
        this.buscarShuck()
      },

      mostrarErrorClick() {
        this.mostrarError=false
      },

      resizeWindow() {
            setTimeout( function () {
                window.dispatchEvent(new Event('resize'))
            }, 250)
        },

      forcerender() {
        this.componenteInformacion +=1;
      },

      async buscarCourier() {
         
        this.mostrarCargador=true;
         //let ruta ="http://192.168.31.83/consulta_apis.php"
          //let ruta="https://tracking.atika.cl/php/consulta_apis_test.php"
          let ruta="https://tracking.atika.cl/php/consulta_apis.php"
          let params = {'id': this.ID}
          await  axios.get(ruta, {params}).then(response => 
          {
            //this.mostrarCargador=false
            this.encontrado=response.data
            this.respuesta=response.data
            console.log(this.respuesta);
            
            if (this.respuesta.encontrado==false || this.respuesta.encontrado==true)
            {
              this.mostrarCargador=false;
              //this.mostrarError=true
              this.buscarAppControlGuias();
            } else 
            {
              this.forcerender()
              this.resultadoVisible=true
              this.mostrarCargador=false
              this.mostrarCapasResultado=true
            }
                  
          })
        },

        async buscarShuck() {
          
          this.encontradoShuck.ID = 0;
          this.encontradoShuck.nombre = '';
          this.encontradoShuck.direccion = '';
          this.encontradoShuck.status = '';
          this.encontradoShuck.camion = '';
          this.encontradoShuck.fecha_programada = '';

          let id = this.ID;
          id = parseInt(id);
          let estado = '';
          let ruta="https://api.atika.cl/tracking/schuck";
          let parametro = {
            id
            }
          //let params = {'id': this.ID}
          await  axios.post(ruta, parametro).then(response => 
          //await  axios.get(ruta, {params}).then(response => 
          {
              //console.log(response);
              this.mostrarCargador=false
              //this.encontrado=response.data
              let datos = response.data;
              console.log(datos);

              if(datos == 'Despacho invalido'){
                this.buscarCourier();
              }else{
                
                let cliente = datos.cliente;
                let datos_despacho = datos.datos_entrega;
                estado = datos_despacho[0].estado;

                if(estado == 'VIAJE 043648' || estado == 'REPARTO' || estado == 'BODEGA SANTIAGO' || estado == 'BODEGA OSORNO'){
                
                this.encontradoShuck.status = 'Pedido en camino';
                this.encontradoShuck.camion = 'intermedio';

                }else if (estado == 'DIGITALIZADA' || estado == 'RECEPCIONADA' || estado == 'ENTREGADA'){
                  
                  this.encontradoShuck.status = 'Pedido Entregado';
                  this.encontradoShuck.camion = 'final';

                }else{
                  this.encontradoShuck.status = 'Compra Confirmada';
                  this.encontradoShuck.camion = 'confirmada';
                }
                this.encontradoShuck.ID = datos_despacho[0].guia_schuck;
                this.encontradoShuck.nombre = cliente[0].nombre;
                this.encontradoShuck.direccion = cliente[0].direccion;
                this.encontradoShuck.fecha_programada = datos_despacho[0].fecha + ' ' +  datos_despacho[0].hora;
                console.log(this.encontradoShuck);
                this.encontrado = this.encontradoShuck;

                this.forcerender()
                this.resultadoVisible=true
                this.mostrarCargador=false
                this.mostrarCapasResultado=true
              }
           })
        },

        async buscarAppControlGuias() {

          this.encontradoShuck.ID = 0;
          this.encontradoShuck.nombre = '';
          this.encontradoShuck.direccion = '';
          this.encontradoShuck.status = '';
          this.encontradoShuck.camion = '';
          this.encontradoShuck.fecha_programada = '';

          let id = this.ID;
          id = parseInt(id);
          let estado = '';
          let ruta="https://api.atika.cl/tracking/appControl";
          let parametro = {
            id
          }

          console.log(ruta);

          await  axios.post(ruta, parametro).then(response => 
          //await  axios.get(ruta, {params}).then(response => 
          {
              //console.log(response);
              this.mostrarCargador=false;
              //this.encontrado=response.data
              let datos = response.data;
              console.log(datos);

              if(datos == 'guia no despachada' || datos == 'Despacho invalido'){
                this.mostrarCargador=false;
                this.mostrarError=true;
                //this.buscarCourier();
              }else{
                
                this.encontradoShuck.ID = datos.id;
                this.encontradoShuck.nombre = datos.nombre;
                this.encontradoShuck.direccion = datos.direccion;
                this.encontradoShuck.status = datos.status;
                this.encontradoShuck.camion = datos.camion;
                this.encontradoShuck.fecha_programada = datos.fecha_programada;

                this.encontrado = this.encontradoShuck;

                this.forcerender()
                this.resultadoVisible=true
                this.mostrarCargador=false
                this.mostrarCapasResultado=true
              }
           });

        }
    }
  }

  /*

case 1: $resultado->status="Compra confirmada";
                        $resultado->camion="confirmada";       
                        break;
                case 2: $resultado->status="En preparación";
                        $resultado->camion="principio";
                        break;
                case 3: $resultado->status="Pendiente";
                        $resultado->camion="intermedio";
                        break;
                case 4: $resultado->status="Entregada";
                        $resultado->camion="final";

*/

</script>

