<template>
    <div>
        <!--<v-container fluid>
                <Mapa :latitud="encontrado.latitud" :longitud="encontrado.longitud" />
        </v-container>-->
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <v-container fluid>
               <LineaTiempo :valorCamion="encontrado.camion"></LineaTiempo>
        </v-container>
        
        <v-container class="informacion_pedido">
            <v-row>
                <v-col>
                    <v-card
                        outlined color="transparent"
                        elevation="11"
                        class="sinBorde"
                        >
                        <v-card-title
                            color="red"
                            dark
                        >
                            Información del pedido
                        </v-card-title>
                        <v-divider color="#430108"></v-divider>
                        <v-card-text>
                            <v-list-item two-line>
                                <v-list-item-content>
                                    <v-list-item-title>ID del pedido</v-list-item-title>
                                    <v-list-item-subtitle>{{encontrado.ID}}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item two-line>
                                <v-list-item-content>
                                    <v-list-item-title>Nombre cliente</v-list-item-title>
                                    <v-list-item-subtitle>{{encontrado.nombre}}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item two-line>
                                <v-list-item-content>
                                    <v-list-item-title>Dirección de entrega</v-list-item-title>
                                    <v-list-item-subtitle>{{encontrado.direccion}}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col>
                     <v-card
                        shaped
                        elevation="11"
                        outlined color="transparent"
                        class="sinBorde"
                        >
                        <v-card-title
                                dark
                                color="red"
                                >
                            Estado del pedido
                        </v-card-title>
                        <v-divider color="#430108"></v-divider>
                        <v-card-text>
                            <v-list-item two-line>
                                <v-list-item-content>
                                    <v-list-item-title>Estado del pedido</v-list-item-title>
                                    <v-list-item-subtitle v-if="encontrado.camion=='problema'">
                                                Hemos tenido problemas con tu entrega. 
                                                <br />
                                                Por favor <a href='https://www.atika.cl/contacto' class="rojo" target="_blank">contáctanos</a>. 
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle v-else>{{encontrado.status}}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item two-line>
                                <v-list-item-content>
                                     <v-list-item-title v-if="encontrado.camion=='final'">
                                            Pedido entregado el
                                     </v-list-item-title>
                                     <!-- 
                                     <v-list-item-title v-else>Fecha estimada de entrega</v-list-item-title>
                                     --> 
                                     <v-list-item-subtitle v-if="encontrado.camion=='final'">{{encontrado.fecha_programada}}</v-list-item-subtitle>
                                
                                    </v-list-item-content>
                            </v-list-item>
                            
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<style>



</style>


<script>
import LineaTiempo  from '@/components/LineaTiempo.vue'
import Mapa from '@/components/Mapa'

export default {
    name:'InformacionPedido', 

    props: ["encontrado"], 

    
    components: {
        Mapa,
        LineaTiempo
    }, 
    
    created() {
        
    },

    data() {
        return {
            mostrarCargador:true,
            mostrarError:false,
            latitud:'',
            longitud:''

        }
    }, 

}
</script>


