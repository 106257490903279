<template>
    
    <div>
        <v-container>
            <v-row>
                <v-col lg="9">
                    <a href="https://www.atika.cl">
                        <v-img
                        max-width="200px" 
                        src="@/assets/logo-atika-header.png">
                        </v-img>
                    </a>
                    
                </v-col>
                <v-col lg="3">
                    <v-btn
                        class="ma-2 botonSuperior"
                        outlined
                        color="black"
                        @click="seguirComprando"
                        >
                        <v-icon>
                            mdi-arrow-left-thick
                        </v-icon>
                        Seguir comprando
                    </v-btn>
                </v-col>
                </v-row>
        </v-container>
        <hr />
    </div>
</template>

<script>
export default {
  name: 'Header',
  props: {
    msg: String
  }, 

  methods: {
    seguirComprando:function() {
        history.back();
    }
  }
 
}
</script>


