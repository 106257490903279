<template>
    <l-marker
        :key="marker.id"
        :lat-lng="coordenadas"
    >
        <l-icon ref="icon">
            <img class="atika-icon" :src="marker.url" />
        </l-icon>
    </l-marker>
</template>

<script>

    import {LIcon,LMarker} from 'vue2-leaflet'

    export default {
        name:'Marcador',
        components: {
            LIcon,LMarker
        },
        props: ['latitud','longitud'],
        data() {
            return {
                coordenadas:[],
                marker: {
                    id:1,
                    url:'imagenes/placeholder.png'
                } 
            }
        }, 
        created() {
            this.coordenadas=['-33.38580', '-70.74924']
        }, 

        watch: {
            latitud: function(valorAntiguo,valorNuevo) { 
                this.coordenadas=[this.latitud,this.longitud]
            }
        }
    }

</script>

<style>
    .atika-icon {
        height:50px;
        width:auto;
    }

    .leaflet-default-icon-path {
        background:none;
    }

    .leaflet-default-shadow-path {
        background:none;
    }
</style>