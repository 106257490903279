<template>
    <div class="formulario">
        <v-container>
                <v-row>
                    <v-col>
                        <h3>
                            Sigue tu pedido
                        </h3>
                        <hr class="lineaRoja">
                        <span class="textoBajada">
                            Por favor ingrese el número de la boleta,factura o número de pedido para realizar el seguimiento de tu compra.
                        </span>
                    </v-col> 
                </v-row>
                <v-row>
                    <v-col lg="6">
                        <v-text-field 
                            type="text" 
                            class="textoSeguimiento"
                            v-model="seguimiento"
                            v-bind:id="seguimiento"
                            ref="inputSeguimiento"
                            hide-details="auto" >
                        </v-text-field>
                    </v-col>
                    <v-col lg="3">
                        <v-btn
                            class="mx-3 white--text botonRojo" 
                            dark
                            v-on:click="buscarCourier">
                            Buscar
                            <v-icon>
                                mdi-magnautocompleteify
                            </v-icon>
                        </v-btn>
                    </v-col>
                </v-row>      
        </v-container>
    </div>
</template>
<script>
import enPreparacion from './iconos/enPreparacion.vue'
export default {
    name: "Formulario",
    data() {
        return {
            seguimiento: "",
            mostrarCargador: true
        };
    },
    mounted() {
        this.agregarAutocomplete();
    },
    methods: {
        buscarCourier() {
            this.$emit("ingresado", this.seguimiento);
        },
        agregarAutocomplete() {
            this.$refs.inputSeguimiento.autocomplete = "on";
            // document.getElementById("seguimiento").autocomplete="on"
        }
    }
}

/*
    Código simpliroute : 1228213444397-01
*/
</script>
